
const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: () => import('pages/ChartsPage.vue')
      },
      {
        path: 'reports',
        component: () => import('pages/ReportsPage.vue')
      },
      {
        path: 'conversions',
        component: () => import('pages/ConversionsPage.vue')
      },
      {
        path: 'offers',
        component: () => import('pages/OffersPage.vue')
      },
      {
        path: 'commissions',
        component: () => import('pages/CommissionsPage.vue')
      },
      {
        path: 'tracking',
        component: () => import('pages/TrackingPage.vue')
      },
      {
        path: 'affiliate-commissions',
        component: () => import('pages/AffiliateCommissionsPage.vue')
      },
      {
        path: 'affiliate-manager',
        component: () => import('pages/AffManagerPage.vue')
      },
      {
        path: 'affiliates',
        component: () => import('pages/AffiliatesPage.vue')
      },
      {
        path: 'affiliates/create',
        component: () => import('pages/AffiliateDetailsPage.vue')
      },
      {
        path: 'affiliates/:id',
        component: () => import('pages/AffiliateDetailsPage.vue')
      },
      {
        path: 'users',
        component: () => import('pages/UsersPage.vue')
      },
      {
        path: 'users/create',
        component: () => import('pages/UserDetailsPage.vue')
      },
      {
        path: 'users/:id',
        component: () => import('pages/UserDetailsPage.vue')
      },
      {
        path: 'domains',
        component: () => import('pages/DomainsPage.vue')
      },
      {
        path: 'payment-information',
        component: () => import('pages/PaymentInformationPage.vue')
      },
      {
        path: 'postback_responses',
        component: () => import('pages/PostbackResponsesPage.vue')
      },
    ]
  },

  {
    path: '/',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      { path: 'login', component: () => import('pages/LogInPage.vue') },
      { path: 'register', component: () => import('pages/RegisterPage.vue') },
      { path: 'forgot-password', component: () => import('pages/ForgotPasswordPage.vue') },
      { path: 'reset-password', component: () => import('pages/ResetPasswordPage.vue') }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
