import {defineStore} from 'pinia';
import {router} from "src/helpers";
import {fetchWrapper} from "src/helpers/fetch-wrapper";

export const API_URL = process.env.API_URL;

export const URL_SCHEME = process.env.URL_SCHEME;

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem('trk_user')),
    impersonator: JSON.parse(localStorage.getItem('trk_impersonator')),
    returnUrl: null
  }),
  actions: {
    async trkLogin(email, password) {
      const trkUser = await fetchWrapper.post(`${API_URL}/v1/authenticate`, {email, password});

      this.user = trkUser;
      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setItem('trk_user', JSON.stringify(trkUser));

      // redirect to previous url or default to home page
      router.push(this.returnUrl || '/');
    },
    async logout() {
      if (this.isImpersonating()) {
        this.stopImpersonate();
        return;
      }

      this.user = null;
      localStorage.removeItem('trk_user');
      router.push('/login');
    },
    isManagerOrAdmin() {
      return this.user?.role === 'Affiliate Manager'
        || this.user?.role === 'Admin';
    },
    isManager() {
      return this.user?.role === 'Affiliate Manager';
    },
    isAdmin() {
      return this.user?.role === 'Admin';
    },
    async trkImpersonate(id) {
      const trkUser = await fetchWrapper.post(`${API_URL}/v1/impersonate`, {id});

      this.impersonator = this.user;
      localStorage.setItem('trk_impersonator', JSON.stringify(this.impersonator));

      this.user = trkUser;
      localStorage.setItem('trk_user', JSON.stringify(trkUser));

      window.location = '/';
    },
    async stopImpersonate() {
      let push_route = '/affiliates';
      if (this.isManager()) {
        push_route = '/users';
      }

      this.user = this.impersonator;
      localStorage.setItem('trk_user', JSON.stringify(this.impersonator));

      this.impersonator = null;
      localStorage.removeItem('trk_impersonator');

      router.push(push_route);

      setTimeout(() => {
        router.go();
      }, 500);
    },
    isImpersonating() {
      return this.impersonator !== null;
    },
    async trkForgotPassword(email) {
      const resp = await fetchWrapper.post(`${API_URL}/v1/forgot-password`, {email});

      return resp?.message;
    },
    async trkResetPassword(email, token, password, password_confirmation) {
      const resp = await fetchWrapper.post(`${API_URL}/v1/reset-password`, {
        email,
        token,
        password,
        password_confirmation,
      });

      return resp?.message;
    },
  }
});
