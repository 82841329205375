import {createMemoryHistory, createRouter, createWebHashHistory, createWebHistory} from 'vue-router';

import {useAuthStore} from "stores/auth.store";
import routes from "./routes";

const createHistory = process.env.SERVER
  ? createMemoryHistory
  : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

export const router = createRouter({
  // Leave this as is and make changes in quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  history: createHistory(process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE),
  linkActiveClass: 'active',
  routes: routes
});

router.beforeEach(async (to) => {
  if (process.env.URL_SCHEME === 'https://'
    && window.location.protocol !== 'https:') {
    window.location.href = window.location.href.replace('http://', 'https://');
  }

  // Redirect to login page if not logged in
  // and trying to access a restricted page
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const auth = useAuthStore();

  if (requiresAuth && !auth.user) {
    auth.returnUrl = to.fullPath;
    return '/login';
  }
});
