import {boot} from 'quasar/wrappers'
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";
import {router} from 'src/helpers'

const SENTRY_DSN = process.env.SENTRY_DSN ?? '';

export default boot(({ app }) => {
  if (SENTRY_DSN.length === 0) {
    return;
  }

  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "partner.advivus.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
});
