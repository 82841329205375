import {useAuthStore} from "stores/auth.store";
import {Notify} from 'quasar';
import {flatten} from 'q-flat';

const APP_ENV = process.env.APP_ENV ?? '';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body, formData) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };
        requestOptions.headers['Cookie'] = document.cookie;

        if (formData instanceof FormData) {
          const flatBody = flatten(body);
          for (const [key, value] of Object.entries(flatBody)) {
            formData.append(key, value)
          }

          // Workaround for bug with php - check https://stackoverflow.com/a/61768745
          if (requestOptions.method !== 'POST') {
            formData.append('_method', requestOptions.method)
            requestOptions.method = 'POST';
          }

          requestOptions.body = formData;
        } else {
          requestOptions.headers['Content-Type'] = 'application/json';

          if (body && method !== 'GET') {
            requestOptions.body = JSON.stringify(body);
          }
          else if (body && method === 'GET') {
            url += '?' + new URLSearchParams(flatten(body))
          }
        }

        return fetch(url, requestOptions).then(handleResponse);
    }
}

// helper functions

function authHeader(url) {
    // return auth header with token if user is logged in and request is to the api url
    const { user } = useAuthStore();
    const isLoggedIn = !!user?.trk_token;
    // const isApiUrl = url.startsWith('/api');

    if (isLoggedIn) {
        return {
          Accept: 'application/json',
          Authorization: `Bearer ${user.trk_token}`
        };
    } else {
        return {};
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (response.ok) {
          return data;
        }

        const { user, logout } = useAuthStore();
        if ([401].includes(response.status) && user) {
          // auto logout if 401 Unauthorized response returned from api
          logout();
        }

        // Hide 500 from user on prod.
        if ([500].includes(response.status)) {
          if (APP_ENV !== 'production') {
            Notify.create({
              type: 'negative',
              message: data?.message ?? 'Request failed, please try again.'
            })
          }
        } else {
          Notify.create({
            type: 'negative',
            message: data?.message ?? 'Request failed, please try again.'
          })
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
    });
}
