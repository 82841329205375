import {useRoute} from "vue-router";
import {watch} from "vue";
import {isEmpty, isNil, omitBy} from "lodash";
import {router} from "src/helpers";

export function DeepLinkPinia({options, store}) {
  if (options.actions === undefined
    || typeof options.actions.syncQueryStates !== 'function') {
    return;
  }

  const route = useRoute();

  const states = options.actions.syncQueryStates(route.path, store.$state);
  if (typeof states !== 'object') {
    return;
  }

  const states_keys = Object.keys(states);
  if (!states_keys.length) {
    return;
  }

  states_keys.forEach(s_key => {
    // Sync query string param to state.
    if (route.query.hasOwnProperty(s_key)) {
      const q_params = JSON.parse(route.query[s_key])
      Object.keys(q_params).forEach(q_key => {
        states[s_key][q_key] = q_params[q_key];
      });
    }

    // Sync state param to query string.
    watch(
      states[s_key],
      (new_state) => {
        let new_query = {
          ...route.query,
        };

        let params = {...new_state};
        params = omitBy(params, isNil);
        params = omitBy(params, isEmpty);
        new_query[s_key] = JSON.stringify(params);

        router.push({
          query: new_query
        });
      },
      {deep: true}
    )
  });

}
